import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSocialEmpDemographicInputs, fetchSocialEmpWellBeingInputs } from "../../../../features/social/socialThunk";
import useApi from "../../../../customHooks/useApi";
import { filterDataByKeys, getLatestYearObjects } from "../../function/DataProcessFunction";
const WellBeingTable = () => {
  const dispatch=useDispatch()
  const api=useApi()
  const {socialEmpDemographicsRecords,socialEmpWellBeingsRecords}=useSelector(state=>state.social)
  const returnSocialEmpWellBeingsRecords=getLatestYearObjects(socialEmpWellBeingsRecords)
  const returnSocialEmpDemographicsRecords=getLatestYearObjects(socialEmpDemographicsRecords)
const permanentMale=filterDataByKeys(returnSocialEmpWellBeingsRecords,{employement_category:"Permanent Employees",gender:"male"})
const permanentFemale=filterDataByKeys(returnSocialEmpWellBeingsRecords,{employement_category:"Permanent Employees",gender:"female"})
const permanentEmpDemographicMale=filterDataByKeys(returnSocialEmpDemographicsRecords,{employement_category:"Full Time",gender:"male"})
const permanentEmpDemographicFemale=filterDataByKeys(returnSocialEmpDemographicsRecords,{employement_category:"Full Time",gender:"female"})
// console.log({permanentEmpDemographicMale,permanentEmpDemographicFemale})

  const data1 = [
    {
      category: "Male",
      total: "100",
      healthInsurance: { number: "90", percentage: "90%" },
      accidentInsurance: { number: "85", percentage: "85%" },
      maternityBenefits: { number: "0", percentage: "0%" },
      paternityBenefits: { number: "80", percentage: "80%" },
      dayCareFacilities: { number: "70", percentage: "70%" },
     
    },
    {
      category: "Female",
      total: "80",
      healthInsurance: { number: "70", percentage: "88%" },
      accidentInsurance: { number: "65", percentage: "81%" },
      maternityBenefits: { number: "60", percentage: "75%" },
      paternityBenefits: { number: "0", percentage: "0%" },
      dayCareFacilities: { number: "50", percentage: "63%" },
   
    },
    {
      category: "Total",
      total: "180",
      healthInsurance: { number: "160", percentage: "89%" },
      accidentInsurance: { number: "150", percentage: "83%" },
      maternityBenefits: { number: "60", percentage: "33%" },
      paternityBenefits: { number: "80", percentage: "44%" },
      dayCareFacilities: { number: "120", percentage: "67%" },
  
    },
  ];
const data2 = [
    {
      category: "Male",
      total: "100",
      healthInsurance: { number: "90", percentage: "90%" },
      accidentInsurance: { number: "85", percentage: "85%" },
      maternityBenefits: { number: "0", percentage: "0%" },
      paternityBenefits: { number: "80", percentage: "80%" },
      dayCareFacilities: { number: "70", percentage: "70%" },
     
    },
    {
      category: "Female",
      total: "80",
      healthInsurance: { number: "70", percentage: "88%" },
      accidentInsurance: { number: "65", percentage: "81%" },
      maternityBenefits: { number: "60", percentage: "75%" },
      paternityBenefits: { number: "0", percentage: "0%" },
      dayCareFacilities: { number: "50", percentage: "63%" },
   
    },
    {
      category: "Total",
      total: "180",
      healthInsurance: { number: "160", percentage: "89%" },
      accidentInsurance: { number: "150", percentage: "83%" },
      maternityBenefits: { number: "60", percentage: "33%" },
      paternityBenefits: { number: "80", percentage: "44%" },
      dayCareFacilities: { number: "120", percentage: "67%" },
  
    },
  ];
const data3 = [
    {
      category: "Male",
      total: "100",
      healthInsurance: { number: "90", percentage: "90%" },
      accidentInsurance: { number: "85", percentage: "85%" },
      maternityBenefits: { number: "0", percentage: "0%" },
      paternityBenefits: { number: "80", percentage: "80%" },
      dayCareFacilities: { number: "70", percentage: "70%" },
     
    },
    {
      category: "Female",
      total: "80",
      healthInsurance: { number: "70", percentage: "88%" },
      accidentInsurance: { number: "65", percentage: "81%" },
      maternityBenefits: { number: "60", percentage: "75%" },
      paternityBenefits: { number: "0", percentage: "0%" },
      dayCareFacilities: { number: "50", percentage: "63%" },
   
    },
    {
      category: "Total",
      total: "180",
      healthInsurance: { number: "160", percentage: "89%" },
      accidentInsurance: { number: "150", percentage: "83%" },
      maternityBenefits: { number: "60", percentage: "33%" },
      paternityBenefits: { number: "80", percentage: "44%" },
      dayCareFacilities: { number: "120", percentage: "67%" },
  
    },
  ];
const data4 = [
    {
      category: "Male",
      total: "100",
      healthInsurance: { number: "90", percentage: "90%" },
      accidentInsurance: { number: "85", percentage: "85%" },
      maternityBenefits: { number: "0", percentage: "0%" },
      paternityBenefits: { number: "80", percentage: "80%" },
      dayCareFacilities: { number: "70", percentage: "70%" },
     
    },
    {
      category: "Female",
      total: "80",
      healthInsurance: { number: "70", percentage: "88%" },
      accidentInsurance: { number: "65", percentage: "81%" },
      maternityBenefits: { number: "60", percentage: "75%" },
      paternityBenefits: { number: "0", percentage: "0%" },
      dayCareFacilities: { number: "50", percentage: "63%" },
   
    },
    {
      category: "Total",
      total: "180",
      healthInsurance: { number: "160", percentage: "89%" },
      accidentInsurance: { number: "150", percentage: "83%" },
      maternityBenefits: { number: "60", percentage: "33%" },
      paternityBenefits: { number: "80", percentage: "44%" },
      dayCareFacilities: { number: "120", percentage: "67%" },
  
    },
  ];
 useEffect(()=>{
dispatch(fetchSocialEmpDemographicInputs({api,params:{}}))
dispatch(fetchSocialEmpWellBeingInputs({api,params:{}}))
 },[])
  return (
    <>
    <div className=" w-full  flex justify-center text-sm text-gray-700 border flex-col">
      {/* <h2 className="text-lg font-semibold mb-4">Details of Measures for Employee Well-Being</h2> */}
      <table className="w-full border border-gray-300 text-center  text-gray-700">
        <thead className="bg-gray-100 text-base font-semibold">
          <tr>
      
          
            <th className="border border-gray-300 px-2 py-1 bg-gray-300 text-start font-semibold" colSpan="12">Details of measures for the well-being of employees</th>
          
          </tr>
          {/* <tr>
      
            <th className="border border-gray-300 px-2 py-1 bg-gray-300  text-start font-semibold" colSpan="12">Total number & percentage of various categories covered by: Health insurance, Accident insurance, Maternity benefits, Paternity Benefits & Day Care facilities.</th>
          
          </tr> */}
          <tr>
      
            <th className="border border-gray-300 px-2 py-1" rowSpan="1"></th>
            <th className="border border-gray-300 px-2 py-1 text-sm" colSpan="11">% of employee covered by</th>
          
          </tr>
          <tr>
      
            <th className="border border-gray-300 px-2 py-1 text-sm" rowSpan="2">Category</th>
            <th className="border border-gray-300 px-2 py-1 text-sm" rowSpan="2">Total (A)</th>
            <th colSpan="2" className="border border-gray-300 px-2 py-1 text-sm">Health Insurance</th>
            <th colSpan="2" className="border border-gray-300 px-2 py-1 text-sm">Accident Insurance</th>
            <th colSpan="2" className="border border-gray-300 px-2 py-1 text-sm">Maternity Benefits</th>
            <th colSpan="2" className="border border-gray-300 px-2 py-1 text-sm">Paternity Benefits</th>
            <th colSpan="2" className="border border-gray-300 px-2 py-1 text-sm">Day Care Facilities</th>
          </tr>
          <tr>
            {["Number (B)", "% (B/A)", "Number (C)", "% (C/A)", "Number (D)", "% (D/A)", "Number (E)", "% (E/A)", "Number (F)", "% (F/A)"].map((header, index) => (
              <th key={index} className="border border-gray-300 px-2 py-1 text-sm">{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
        <th className="border border-gray-300 px-2 py-1" rowSpan="1"></th>
        <th className="border border-gray-300 px-2 py-1" colSpan="11">Permanent employee</th>
          {data1.map((row, rowIndex) => (
            <React.Fragment key={rowIndex}>
              <tr className="bg-gray-50">
                <td className="border border-gray-300 px-2 py-1 ">{row.category}</td>
                <td className="border border-gray-300 px-2 py-1">{row.total}</td>
                <td className="border border-gray-300 px-2 py-1">{row.healthInsurance.number}</td>
                <td className="border border-gray-300 px-2 py-1">{row.healthInsurance.percentage}</td>
                <td className="border border-gray-300 px-2 py-1">{row.accidentInsurance.number}</td>
                <td className="border border-gray-300 px-2 py-1">{row.accidentInsurance.percentage}</td>
                <td className="border border-gray-300 px-2 py-1">{row.maternityBenefits.number}</td>
                <td className="border border-gray-300 px-2 py-1">{row.maternityBenefits.percentage}</td>
                <td className="border border-gray-300 px-2 py-1">{row.paternityBenefits.number}</td>
                <td className="border border-gray-300 px-2 py-1">{row.paternityBenefits.percentage}</td>
                <td className="border border-gray-300 px-2 py-1">{row.dayCareFacilities.number}</td>
                <td className="border border-gray-300 px-2 py-1">{row.dayCareFacilities.percentage}</td>
              </tr>
             
            </React.Fragment>
          ))}
        <th className="border border-gray-300 px-2 py-1" rowSpan="1"></th>
        <th className="border border-gray-300 px-2 py-1" colSpan="11">other then Permanent employee</th>
          {data2.map((row, rowIndex) => (
              <React.Fragment key={rowIndex}>
              <tr className="bg-gray-50">
                <td className="border border-gray-300 px-2 py-1 font-medium">{row.category}</td>
                <td className="border border-gray-300 px-2 py-1">{row.total}</td>
                <td className="border border-gray-300 px-2 py-1">{row.healthInsurance.number}</td>
                <td className="border border-gray-300 px-2 py-1">{row.healthInsurance.percentage}</td>
                <td className="border border-gray-300 px-2 py-1">{row.accidentInsurance.number}</td>
                <td className="border border-gray-300 px-2 py-1">{row.accidentInsurance.percentage}</td>
                <td className="border border-gray-300 px-2 py-1">{row.maternityBenefits.number}</td>
                <td className="border border-gray-300 px-2 py-1">{row.maternityBenefits.percentage}</td>
                <td className="border border-gray-300 px-2 py-1">{row.paternityBenefits.number}</td>
                <td className="border border-gray-300 px-2 py-1">{row.paternityBenefits.percentage}</td>
                <td className="border border-gray-300 px-2 py-1">{row.dayCareFacilities.number}</td>
                <td className="border border-gray-300 px-2 py-1">{row.dayCareFacilities.percentage}</td>
              </tr>
             
            </React.Fragment>
          ))}
          </tbody>
          </table>
          <table class="w-full border border-gray-300 text-center  text-gray-700">

          
          <thead className="bg-gray-100 text-base font-semibold">
          <tr>
      
          
            <th className="border border-gray-300 px-2 py-1 bg-gray-300 text-start font-semibold" colSpan="12">Details of measures for the well-being of workers</th>
          
          </tr>
          {/* <tr>
      
            <th className="border border-gray-300 px-2 py-1 bg-gray-300  text-start font-semibold" colSpan="12">Total number & percentage of various categories covered by: Health insurance, Accident insurance, Maternity benefits, Paternity Benefits & Day Care facilities.</th>
          
          </tr> */}
          <tr>
      
            <th className="border border-gray-300 px-2 py-1" rowSpan="1"></th>
            <th className="border border-gray-300 px-2 py-1" colSpan="12">% of workers covered by</th>
          
          </tr>
          <tr>
      
            <th className="border border-gray-300 px-2 py-1 text-sm" rowSpan="2">Category</th>
            <th className="border border-gray-300 px-2 py-1 text-sm" rowSpan="2">Total (A)</th>
            <th colSpan="2" className="border border-gray-300 px-2 py-1 text-sm">Health Insurance</th>
            <th colSpan="2" className="border border-gray-300 px-2 py-1 text-sm">Accident Insurance</th>
            <th colSpan="2" className="border border-gray-300 px-2 py-1 text-sm">Maternity Benefits</th>
            <th colSpan="2" className="border border-gray-300 px-2 py-1 text-sm">Paternity Benefits</th>
            <th colSpan="2" className="border border-gray-300 px-2 py-1 text-sm">Day Care Facilities</th>
          </tr>
          <tr>
            {["Number (B)", "% (B/A)", "Number (C)", "% (C/A)", "Number (D)", "% (D/A)", "Number (E)", "% (E/A)", "Number (F)", "% (F/A)"].map((header, index) => (
              <th key={index} className="border border-gray-300 px-2 py-1 text-sm">{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {/* <th className="border border-gray-300 px-2 py-1" colSpan="12">% of workers covered by</th> */}
          <tr>

          <th className="border border-gray-300 px-2 py-1" rowSpan="1"></th>
        <th className="border border-gray-300 px-2 py-1" colSpan="11">Permanent workers</th>
          </tr>
          {data3.map((row, rowIndex) => (
            <React.Fragment key={rowIndex}>
              <tr className="bg-gray-50">
                <td className="border border-gray-300 px-2 py-1 font-medium">{row.category}</td>
                <td className="border border-gray-300 px-2 py-1">{row.total}</td>
                <td className="border border-gray-300 px-2 py-1">{row.healthInsurance.number}</td>
                <td className="border border-gray-300 px-2 py-1">{row.healthInsurance.percentage}</td>
                <td className="border border-gray-300 px-2 py-1">{row.accidentInsurance.number}</td>
                <td className="border border-gray-300 px-2 py-1">{row.accidentInsurance.percentage}</td>
                <td className="border border-gray-300 px-2 py-1">{row.maternityBenefits.number}</td>
                <td className="border border-gray-300 px-2 py-1">{row.maternityBenefits.percentage}</td>
                <td className="border border-gray-300 px-2 py-1">{row.paternityBenefits.number}</td>
                <td className="border border-gray-300 px-2 py-1">{row.paternityBenefits.percentage}</td>
                <td className="border border-gray-300 px-2 py-1">{row.dayCareFacilities.number}</td>
                <td className="border border-gray-300 px-2 py-1">{row.dayCareFacilities.percentage}</td>
              </tr>
             
            </React.Fragment>
          ))}
        <th className="border border-gray-300 px-2 py-1" rowSpan="1"></th>
        <th className="border border-gray-300 px-2 py-1" colSpan="11">other then Permanent workers</th>
          {data4.map((row, rowIndex) => (
              <React.Fragment key={rowIndex}>
              <tr className="bg-gray-50">
                <td className="border border-gray-300 px-2 py-1 font-medium">{row.category}</td>
                <td className="border border-gray-300 px-2 py-1">{row.total}</td>
                <td className="border border-gray-300 px-2 py-1">{row.healthInsurance.number}</td>
                <td className="border border-gray-300 px-2 py-1">{row.healthInsurance.percentage}</td>
                <td className="border border-gray-300 px-2 py-1">{row.accidentInsurance.number}</td>
                <td className="border border-gray-300 px-2 py-1">{row.accidentInsurance.percentage}</td>
                <td className="border border-gray-300 px-2 py-1">{row.maternityBenefits.number}</td>
                <td className="border border-gray-300 px-2 py-1">{row.maternityBenefits.percentage}</td>
                <td className="border border-gray-300 px-2 py-1">{row.paternityBenefits.number}</td>
                <td className="border border-gray-300 px-2 py-1">{row.paternityBenefits.percentage}</td>
                <td className="border border-gray-300 px-2 py-1">{row.dayCareFacilities.number}</td>
                <td className="border border-gray-300 px-2 py-1">{row.dayCareFacilities.percentage}</td>
              </tr>
             
            </React.Fragment>
          ))}
        </tbody>
      </table></div>
      <div className="w-full mt-5">
      

    </div></>
  );
};

export default WellBeingTable;

