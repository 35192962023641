import React, { useEffect, useState } from "react";

import ChartComponent from "../VisualizationFeature/components/ChartComponentsWaste";

import StackedBarChart from "../VisualizationFeature/components/StackedBarChart";

import PieChart from "../VisualizationFeature/components/PieChart";
import {
  calculateWasteStatistics,
  // calculateWasteStatistics,
  generatePieChartOptions,
  generateSeriesDataForStackedChart,
  generateSingleCandleChartOptions,
  generateStackedChartOptions,
  processStackedChartData,
} from "../VisualizationFeature/Redux/processData/ProcessWaste";
import { calculateWasteTreatedPercentage } from "../VisualizationFeature/Redux/processData/ProcessWaste";
import { formatText } from "../VisualizationFeature/Redux/Processdata";
import WasteProducedTable from "./WasteProducedTable";

function WasteMain({
  width,
  height,
  filterBy,
  data,
  chartDataArray,
  Options,
  StackBreakDown,
  Filterkey,
  currentFinancialYear,
}) {
  const [typeBreakdown, setTypeBreakdown] = useState("");
  const [tableData, setTableData] = useState([]);
  const [options, setOptions] = useState(null); // Initialize options as null
  const [filtredDataByGlobleFilter, setfiltredDataByGlobleFilter] = useState(
    []
  );
  const updateOptions = (filteredData) => {
    if (filteredData.length > 0) {
      const processed = processStackedChartData(
        filteredData,
        currentFinancialYear,
        typeBreakdown,
        Filterkey
      );

      const filteredDataByCurrentYear = [...filteredData].filter(
        (entry) => Number(entry.year) == Number(currentFinancialYear)
      );

      const wasteTreatedPercentageData = calculateWasteTreatedPercentage(
        filteredDataByCurrentYear,
        "treated"
      );
      const wasteRecoveredPercentageData = calculateWasteTreatedPercentage(
        filteredDataByCurrentYear,
        "recovered"
      );

      const { series, legends } = generateSeriesDataForStackedChart(
        processed,
        wasteTreatedPercentageData,
        wasteRecoveredPercentageData,
        Filterkey
      );

      const xAxisData = Object.keys(processed);
      const updatedOptions = generateStackedChartOptions(
        filteredData,
        currentFinancialYear,
        typeBreakdown,
        legends,
        xAxisData,
        series,
        Filterkey
      );
      setOptions(updatedOptions);
    } else {
      // Handle case where filtered data is empty
      setOptions(null);
    }
  };
  useEffect(() => {
    if (data && data.length > 0) {
      const filteredData = data.filter((entry) => {
        for (let key in filterBy) {
          if (filterBy[key] && entry[key] !== filterBy[key]) {
            return false;
          }
        }
        return true;
      });
      updateOptions(filteredData);
      setfiltredDataByGlobleFilter(filteredData); // filter data according to globle  filter by site , useType and fuel type
    }
  }, [data, filterBy, currentFinancialYear]);

  const handleTypeBreakdownChange = (e) => {
    setTypeBreakdown(e.target.value);
  };
  useEffect(() => {
    if (filtredDataByGlobleFilter.length > 0) {
      setTableData(
        calculateWasteStatistics(
          filtredDataByGlobleFilter,
          currentFinancialYear
        )
      );
      updateOptions(filtredDataByGlobleFilter);
    }
  }, [filtredDataByGlobleFilter, typeBreakdown, data, currentFinancialYear]);
  if (!Array.isArray(data) || data.length === 0) {
    return <div>No data available for Charts</div>;
  }

  return (
    <div>
      <div className="border rounded-md mt-[18px] px-2 py-2  w-full shadow-md">
        <ChartComponent
          Filterkey={Filterkey}
          height={height}
          width={width}
          BreakDownOptions={Options}
          data={filtredDataByGlobleFilter}
        />
      </div>

      {Filterkey === "produced_quantity" && (
        <div className="border rounded-md mt-[18px] px-2 py-2  w-full shadow-md ">
          <h2 className="text-xl font-bold my-2 text-center w-full">
            Sitewise Waste activity
          </h2>
          <WasteProducedTable tableData={tableData} />
        </div>
      )}
      {Filterkey === "produced_quantity" && (
        <div className="border rounded-md mt-[18px] px-2 py-2  w-full shadow-md ">
          <StackedBarChart
            freeSize={true}
            options={generateSingleCandleChartOptions(
              filtredDataByGlobleFilter,
              currentFinancialYear
            )}
          />
        </div>
      )}
      <div className="grid grid-cols-2 gap-2 w-full py-3">
        {chartDataArray.length>=0 &&
          chartDataArray
            .map((item) =>
              generatePieChartOptions(
                item.title,
                filtredDataByGlobleFilter,
                item.dataKey,
                Number(currentFinancialYear),
                Filterkey
              )
            )
            ?.map((options, index) => (
              <div
                key={options?.title?.text + index}
                className="border rounded-md mt-[18px] px-2 py-2 w-full shadow-md"
              >
                <PieChart option={options} />
              </div>
            ))}
      </div>
      <div className="border rounded-md mt-[18px] px-2 py-2 w-full shadow-md">
        <div className=" w-full flex justify-end px-3 gap-3 items-center">
          <label htmlFor="breakpoint">Breakdown :</label>
          <select
            name="breakpoint"
            value={typeBreakdown}
            onChange={handleTypeBreakdownChange}
            className=" py-2 px-4 ml-1 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm "
          >
            <option value="">No Breakdown</option>
            {StackBreakDown.map((option, index) => (
              <option key={`${option}_${index}`} value={option}>
                {formatText(option)}
              </option>
            ))}
          </select>
        </div>
        {options ? ( // Check if options is not null before rendering StackedBarChart
          <StackedBarChart options={options} />
        ) : (
          <div>No data available for Stacked Bar Chart</div>
        )}
      </div>
    </div>
  );
}

export default WasteMain;
