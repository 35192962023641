import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeDuplicates } from "../../../pages/inputScope3/function/removeDuplicates";
import { ReactComponent as RemoveIcon } from "../../../app/assets/DeleteIcon.svg";
import { ReactComponent as CopyIcon } from "../../../app/assets/CopyIcon.svg";
const FormGroup = ({
  formValue,
  waste_material_id,
  treatmentMechanismOptions,
  wasteMaterialOptions,
  handleFormChange,
  currencyOptions,
  unitOptions,
  siteData,
  index,
  handleRemove,
  handleCopy,
  lcaActivityData,
  sourceTypeData,
  
}) => {
  const dispatch = useDispatch();
  let { scope3Category5WasteProduceForm, formValues } = useSelector(
    (state) => state.scope3Category5
  );
  const { produced_period_start, produced_period_end, produced_quantity } =
    scope3Category5WasteProduceForm;
  const [treated, setTreated] = useState([]);

  useEffect(() => {
    setTreated(
      treatmentMechanismOptions
        .filter((t) => Number(t.waste_material_id) === waste_material_id)
        .map((i) => {
          return {
            id: i.waste_treatment_mechanism_id,
            waste_treatment_mechanism: i.waste_treatment_mechanism,
          };
        })
    );
  }, []);
  
  return (
    <div className="relative grid grid-cols-4 gap-4 px-8 mb-4 mx-4 justify-around border rounded-md p-4">
      <button
        type="button"
        className="absolute right-2 top-5 transform -translate-y-1/2 text-red-500"
        onClick={() => handleRemove(index)}
      >
        <RemoveIcon className="fill-red-500"/>
      </button>
      <button
        type="button"
        className="absolute right-10 top-5 transform -translate-y-1/2 text-green-500 "
        onClick={() => handleCopy(index, formValue)}
      >
        <CopyIcon width={16} height={22} className="fill-green-500"/>
      </button>
      <div className="col-span-1 flex flex-col">
        <label
          htmlFor={`treated_period_start_${index}`}
          className="text-xs py-1.5"
        >
          Period Start<span className="text-red-500">*</span>
        </label>
        <input
          max={produced_period_end}
          min={produced_period_start}
          type="date"
          name="treated_period_start"
          id={`treated_period_start_${index}`}
          value={formValue.treated_period_start || ""}
          onChange={(e) => handleFormChange(e, index)}
          className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
          required
        />
      </div>
      <div className="col-span-1 flex flex-col">
        <label
          htmlFor={`treated_period_end_${index}`}
          className="text-xs py-1.5"
        >
          Period End<span className="text-red-500">*</span>
        </label>
        <input
          max={produced_period_end}
          type="date"
          name="treated_period_end"
          id={`treated_period_end_${index}`}
          value={formValue.treated_period_end || ""}
          onChange={(e) => handleFormChange(e, index)}
          className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
          min={formValue.treated_period_start || ""}
          required
        />
      </div>
      <div className="col-span-1 flex flex-col">
        <label htmlFor={`treated_site_id_${index}`} className="text-xs py-1.5">
          Facility<span className="text-red-500">*</span>
        </label>
        <select
          disabled
          name="treated_site_id"
          id={`treated_site_id_${index}`}
          onChange={(e) => handleFormChange(e, index)}
          className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
          required
          value={formValue.treated_site_id || ""}
        >
          <option value="" disabled>
            Choose the site
          </option>
          {siteData &&
            siteData.map((site) => (
              <option key={site.id} value={site.id}>
                {site.site}
              </option>
            ))}
        </select>
      </div>
      <div className="col-span-1 flex flex-col">
        <label
          htmlFor={`waste_material_id_${index}`}
          className="text-xs py-1.5"
        >
          Waste Material<span className="text-red-500">*</span>
        </label>
        <select
          disabled
          name="waste_material_id"
          id={`waste_material_id_${index}`}
          onChange={(e) => handleFormChange(e, index)}
          className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
          required
          value={formValue.waste_material_id || ""}
        >
          <option value="" disabled>
            Choose the waste material
          </option>
          {wasteMaterialOptions &&
            wasteMaterialOptions.map((material) => (
              <option key={material.id} value={material.id}>
                {material.waste_material}
              </option>
            ))}
        </select>
      </div>
      <div className="col-span-1 flex flex-col">
        <label
          htmlFor={`treatment_mechanism_id_${index}`}
          className="text-xs py-1.5"
        >
          Treatment Mechanism<span className="text-red-500">*</span>
        </label>
        <select
          name="treatment_mechanism_id"
          id={`treatment_mechanism_id_${index}`}
          value={formValue.treatment_mechanism_id || ""}
          onChange={(e) => handleFormChange(e, index)}
          className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
          required
        >
          <option value="">Choose the treatment mechanism</option>
          {treatmentMechanismOptions &&
            treatmentMechanismOptions
              .filter((i) => waste_material_id == i.waste_material_id)
              .map((mechanism) => (
                <option
                  key={mechanism.id}
                  value={mechanism.waste_treatment_mechanism_id}
                >
                  {mechanism.waste_treatment_mechanism}
                </option>
              ))}
        </select>
      </div>
      <div className="col-span-1 flex flex-col">
        <label htmlFor={`source_type_id_${index}`} className="text-xs py-1.5">
          Emission Factor Database<span className="text-red-500">*</span>
        </label>

        <select
          name="source_type_id"
          id={`source_type_id_${index}`}
          value={formValue.source_type_id || ""}
          onChange={(e) => handleFormChange(e, index)}
          className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
          required
        >
          <option value="">Choose the source type</option>
          {sourceTypeData &&
            removeDuplicates(
              sourceTypeData.filter(
                (i) =>
                  Number(i.waste_treatment_mechanism_id) ===
                    Number(formValue.treatment_mechanism_id) &&
                  Number(i.waste_material_id) ===
                    Number(formValue.waste_material_id)
              ),
              "source_type"
            ).map((source) => (
              <option key={source.id} value={source.id}>
                {source.source_type}
              </option>
            ))}
        </select>
      </div>
      <div className="col-span-1 flex flex-col">
        <label htmlFor={`lca_activity_id_${index}`} className="text-xs py-1.5">
          LCA Activity<span className="text-red-500">*</span>
        </label>
        <select
          name="lca_activity_id"
          id={`lca_activity_id_${index}`}
          value={formValue.lca_activity_id || ""}
          onChange={(e) => handleFormChange(e, index)}
          className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
          required
        >
          <option value="">Choose the LCA activity</option>
          {lcaActivityData &&
            removeDuplicates(
              lcaActivityData?.filter(
                (i) =>
                  Number(i.waste_treatment_mechanism_id) ===
                    Number(formValue.treatment_mechanism_id) &&
                  Number(i.waste_material_id) ===
                    Number(formValue.waste_material_id)
              ),
              "lca_activity"
            )?.map((activity) => (
              <option key={activity.id} value={activity.id}>
                {activity.lca_activity}
              </option>
            ))}
        </select>
      </div>
      <div className="col-span-1 flex flex-col">
        <label
          htmlFor={`treated_proportion_of_waste_${index}`}
          className="text-xs py-1.5"
        >
          Proportion of Waste Treated (%)
        </label>
        <input
          type="number"
          name="treated_proportion_of_waste"
          id={`treated_proportion_of_waste_${index}`}
          value={formValue.treated_proportion_of_waste || ""}
          onChange={(e) => handleFormChange(e, index)}
          className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
          placeholder="Type the proportion"
          max={100}
          onInput={(e) =>
            (e.target.value = Math.min(100, Number(e.target.value)))
          }
          // required
        />
      </div>
      <div className="col-span-1 flex flex-col">
        <label htmlFor={`treated_quantity_${index}`} className="text-xs py-1.5">
          Treated Quantity
        </label>
        <input
          type="number"
          name="treated_quantity"
          id={`treated_quantity_${index}`}
          value={formValue.treated_quantity || ""}
          onChange={(e) => handleFormChange(e, index)}
          className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
          placeholder="Type the quantity"
          min="0"
        />
      </div>
      <div className="col-span-1 flex flex-col">
        <label htmlFor={`treated_unit_id_${index}`} className="text-xs py-1.5">
          Unit
        </label>
        <select
        disabled
          name="treated_unit_id"
          id={`treated_unit_id_${index}`}
          value={formValue.treated_unit_id || ""}
          onChange={(e) => handleFormChange(e, index)}
          className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
        >
          <option value="" disabled>
            Choose the unit
          </option>
          {unitOptions &&
            unitOptions.map((unit) => (
              <option key={unit.id} value={unit.id}>
                {unit.unit}
              </option>
            ))}
        </select>
      </div>
      <div className="col-span-1 flex flex-col">
        <label
          htmlFor={`treated_amount_spent_${index}`}
          className="text-xs py-1.5"
        >
          Amount Spent<span className="text-red-500">*</span>
        </label>
        <input
          type="number"
          name="treated_amount_spent"
          id={`treated_amount_spent_${index}`}
          value={formValue.treated_amount_spent || ""}
          onChange={(e) => handleFormChange(e, index)}
          min="0"
          step="0.01"
          placeholder="Type the amount"
          className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
          required
        />
      </div>
      <div className="col-span-1 flex flex-col">
        <label
          htmlFor={`treated_currency_id_${index}`}
          className="text-xs py-1.5"
        >
          Currency<span className="text-red-500">*</span>
        </label>
        <select
          name="treated_currency_id"
          id={`treated_currency_id_${index}`}
          value={formValue.treated_currency_id || ""}
          onChange={(e) => handleFormChange(e, index)}
          className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
          required
        >
          <option value="" disabled>
            Choose the currency
          </option>
          {currencyOptions &&
            currencyOptions.map((currency) => (
              <option key={currency.id} value={currency.id}>
                {currency.currency}
              </option>
            ))}
        </select>
      </div>
      <div className=" flex flex-col">
        <label htmlFor={`treated_evidence_${index}`} className="text-xs py-1.5 flex flex-col">
          <span>Evidence <span className="text-red-500">*</span></span> 
          {formValue.treated_evidence ? (
            <p className="text-green-600 mt-1 underline cursor-pointer">
              Evidence Uploaded
            </p>
          ) : (
           ""
          )}
        </label>
        <input
          required={!formValue.treated_evidence}
          type="file"
          name="treated_evidence"
          id={`treated_evidence_${index}`}
          multiple
          accept=".jpg, .jpeg, .png, .pdf, .zip"
          onChange={(e) => handleFormChange(e, index)}
          className={`appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none ${formValue.treated_evidence ? 'hidden' : ''}`}
        />
      </div>
    </div>
  );
};

export default FormGroup;
