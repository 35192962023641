import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const HiringAndTurnoverForm = ({handleSubmitData,siteOptions}) => {
  const loading = useSelector((state) => state.common.isLoading);
  const {financialYearData} = useSelector((state) => state.social);
  
  
  const initialData = {
    site: null,
    financialYear: "",
    organization_level_flag:false,
    male: {
      '<20': { recruits: '0', left: '0', total: '0' },
      '20-30': { recruits: '0', left: '0', total: '0' },
      '31-50': { recruits: '0', left: '0', total: '0' },
      '>50': { recruits: '0', left: '0', total: '0' },
    },
    female: {
      '<20': { recruits: '0', left: '0', total: '0' },
      '20-30': { recruits: '0', left: '0', total: '0' },
      '31-50': { recruits: '0', left: '0', total: '0' },
      '>50': { recruits: '0', left: '0', total: '0' },
    },
    other: {
      '<20': { recruits: '0', left: '0', total: '0' },
      '20-30': { recruits: '0', left: '0', total: '0' },
      '31-50': { recruits: '0', left: '0', total: '0' },
      '>50': { recruits: '0', left: '0', total: '0' },
    },
    notDisclosed: {
      '<20': { recruits: '0', left: '0', total: '0' },
      '20-30': { recruits: '0', left: '0', total: '0' },
      '31-50': { recruits: '0', left: '0', total: '0' },
      '>50': { recruits: '0', left: '0', total: '0' },
    },
  };
// console.log(handleSubmitData)
  const [formData, setFormData] = useState(initialData);

  const handleInputChange = (gender, ageGroup, field, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [gender]: {
        ...prevState[gender],
        [ageGroup]: {
          ...prevState[gender][ageGroup],
          [field]: value,
        },
      },
    }));
  };
  const handleRadioChange = (value) => {
    setFormData((prevState) => ({
      ...prevState,
      organization_level_flag: value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    handleSubmitData(formData)
 
  };

  const renderTable = (gender) => {
    return (
      <>
        <div className="grid grid-cols-4 gap-4 font-semibold text-gray-700 mt-6">
          <div>Age Group</div>
          <div>New Recruits</div>
          <div>Number of Employees Left</div>
          <div>Total Employees</div>
        </div>
        {['<20', '20-30', '31-50', '>50'].map((ageGroup) => (
          <div className="grid grid-cols-4 gap-4 mt-4" key={ageGroup}>
            <div className="text-gray-700">{ageGroup} years</div>
            {['recruits', 'left', 'total'].map((field) => (
              <input
              required
                key={field}
                type="number"
                className="bg-gray-200 text-neutral-700 text-xs py-1.5 px-1 border rounded w-full"
                value={formData[gender][ageGroup][field]}
                onChange={(e) =>
                  handleInputChange(gender, ageGroup, field, e.target.value)
                }
              />
            ))}
          </div>
        ))}
      </>
    );
  };

  return (
    <div className=" bg-white rounded-md w-full h-screen  p-2 px-3">

      <form onSubmit={handleSubmit}>
        <div className='flex justify-between gap-5 mb-4' >
        {
         <div className="w-full">
        <label className="block mb-2 font-medium" htmlFor="organization_level_flag">
          Organization Level
        </label>
        <div className="flex gap-2 items-center">
          <div className="flex items-center">
            <input
              type="radio"
              name="organization_level_flag"
              id="organization_level_flag-true"
              checked={formData.organization_level_flag === true}
              onChange={() => handleRadioChange(true)}
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            />
            <label className="ml-2" htmlFor="organization_level_flag-true">
              Yes
            </label>
          </div>
          <div className="flex items-center">
            <input
              type="radio"
              name="organization_level_flag"
              id="organization_level_flag-false"
              checked={formData.organization_level_flag === false}
              onChange={() => handleRadioChange(false)}
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            />
            <label className="ml-2" htmlFor="organization_level_flag-false">
              No
            </label>
          </div>
        </div>
      </div>}
     { !formData?.organization_level_flag  &&<div className="w-full ">
          <label className="block text-gray-700 font-medium mb-2" htmlFor="site">
            Choose Site
          </label>
          <select
        
            id="site"
            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-green-500"
            value={formData.site}
            required
            onChange={(e) => setFormData({ ...formData, site: e.target.value })}
          >
            <option value="">Select Site</option>
            {siteOptions&&siteOptions.map(({site,id})=><option value={id}>{site}</option>)}
          </select>
        </div>}
        <div className="w-full">
          <label
            className="block text-gray-700 font-medium mb-2"
            htmlFor="financialYear"
          >
            Financial Year
          </label>
          <select
      className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-green-500"
      value={formData.financialYear}
      required
      onChange={(e) => setFormData({ ...formData, financialYear: e.target.value })}
    >
      <option value="">Select financial year</option>
      {financialYearData.map((year) => (
        <option key={year} value={year}>
          {year}
        </option>
      ))}
    </select>

        </div>

        </div>
        {/* Male Section */}
        <div className="mb-6">
          <h3 className="text-xl font-medium text-center">Male</h3>
          {renderTable('male')}
        </div>

        {/* Female Section */}
        <div className="mb-6">
          <h3 className="text-xl font-medium text-center">Female</h3>
          {renderTable('female')}
        </div>

        {/* Other Section */}
        <div className="mb-6">
          <h3 className="text-xl font-medium text-center">Other</h3>
          {renderTable('other')}
        </div>

        {/* Not Disclosed Section */}
        <div className="mb-6">
          <h3 className="text-xl font-medium text-center">Not Disclosed</h3>
          {renderTable('notDisclosed')}
        </div>

        <div className="px-4 w-full py-3 flex gap-x-6 gap-y-4 items-center justify-center">
        <button
          type="submit"
          disabled={loading}
          className={`py-2 px-6 text-white text-sm ${
            loading ? 'bg-green-500 cursor-wait' : 'bg-green-600'
          } rounded-md`}
        >
          Save and Continue
        </button>
      </div>
      </form>
    </div>
  );
};

export default HiringAndTurnoverForm;
