import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { useEffect, useState } from "react";

import { toast } from "react-toastify";
import useApi from "../../../customHooks/useApi";

import {
  postscope3Category5WasteTreatedData,
  scope3Category5UploadEvidence,
  updatescope3Category5Data,
} from "../../../features/scope3/category5/Scope3Category5Thunk";

import FormGroup from "./FormGroup";
import {
  setFormValues,
  setscope3Category5WasteTreatedForm,
} from "../../../features/scope3/category5/Scope3Category5Slice";
import { calculateAllowableQuantity, calculateProportion, calculateQuantity } from "./process";

const Category5WasteTreatedFormEditAndCopyForm = ({closePurchasePopupForm, actionType }) => {
  const api = useApi();
  // const [totalTreatedQuantity, settotalTreatedQuantity] = useState(0);
  const loading = useSelector((state) => state.common.isLoading);
  const dispatch = useDispatch();
  const [files, setFiles] = useState(null);
  const [message,setMessage]=useState(
    ''
  )
  const [isExceed, setisExceed] = useState(false);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [isPropotionUpdate, setIsPropotionUpdate] = useState(false);
  const currencyOptions = useSelector(
    (state) => state.scope3Category5.currencyData
  );
  const unitOptions = useSelector((state) => state.scope3Category5.unitData);
  const siteData = useSelector((state) => state.scope3Category5.siteData);

  const initialFormValue = {
    treated_period_start: "",
    treated_period_end: "",
    treated_site_id: "",
    waste_material: "",
    waste_material_id: "",
    treated_treatment_mechanism: "",
    treatment_mechanism_id: "",
    treated_proportion_of_waste: "",
    treated_quantity: "",
    treated_unit_id: "",
    treated_amount_spent: "",
    treated_currency_id: "",
    treated_evidence: null,
  };
  let {
    scope3Category5WasteProduceForm,
    treatmentMechanism,
    wasteMaterial,
    lcaActivityData,sourceTypeData,
    wasteTreatmentMapping,
    formValues,
  } = useSelector((state) => state.scope3Category5);
  const { produced_period_start, produced_period_end, produced_quantity } =
    scope3Category5WasteProduceForm;
  const handleFormChange = async (e, index) => {
    const { name, value, files } = e.target;

    // Copy the current form values
    const updatedFormValues = [...formValues];

    // Determine the new values for waste_material and treated_treatment_mechanism
    const currentWasteMaterial = updatedFormValues[index].waste_material || "";
    const currentTreatedTreatmentMechanism =
      updatedFormValues[index].treated_treatment_mechanism || "";

    let waste_material = currentWasteMaterial;
    let treated_treatment_mechanism = currentTreatedTreatmentMechanism;

    if (name === "waste_material_id") {
      const selectedWasteMaterial = wasteMaterial.find(
        (item) => item.id == value
      );
      waste_material = selectedWasteMaterial
        ? selectedWasteMaterial.waste_material
        : "";
    }

    if (name === "treatment_mechanism_id") {
      const selectedTreatedMaterial = treatmentMechanism.find(
        (item) => item.id == value
      );
      treated_treatment_mechanism = selectedTreatedMaterial
        ? selectedTreatedMaterial.waste_treatment_mechanism
        : "";
    }
    if (name === "treated_evidence") {
      const selectedFiles = files;
      try {
        const res = await dispatch(
          scope3Category5UploadEvidence({ api, files: selectedFiles })
        );
        if (res?.payload?.path) {
          updatedFormValues[index] = {
            ...updatedFormValues[index],
            treated_evidence: res.payload.path,
          };
         
          setFiles(selectedFiles);
        } else {
          toast.error("Failed to upload evidence");
        }
      } catch (error) {
        console.error("File upload error:", error);
      }
    }
    updatedFormValues[index] = {
      ...updatedFormValues[index],
      [name]: value,
      waste_material,
      treated_treatment_mechanism,
    };

    if(name==="treated_quantity"){
      if (
        updatedFormValues[index].treated_period_start &&
        updatedFormValues[index].treated_period_end
      ) {
        const TreatedProportion = calculateProportion(
          updatedFormValues[index].treated_period_start,
          updatedFormValues[index].treated_period_end,
          produced_period_start,
          produced_period_end,
          Number(produced_quantity),
          Number(value)
        ).toFixed();
        updatedFormValues[index].treated_proportion_of_waste = TreatedProportion;
      }
    }
    if (
      [
        "treated_period_start",
        "treated_period_end",
        "treated_proportion_of_waste",
      ].includes(name)
    ) {
      if (
        updatedFormValues[index].treated_period_start &&
        updatedFormValues[index].treated_period_end
      ) {
        const TreatedQuantity=calculateQuantity(
          updatedFormValues[index].treated_period_start,
          updatedFormValues[index].treated_period_end,
          produced_period_start,
          produced_period_end,
          Number(produced_quantity),
          Number(updatedFormValues[index].treated_proportion_of_waste)
        ).toFixed()
        if(TreatedQuantity>0){

          updatedFormValues[index].treated_quantity = TreatedQuantity;
        }else{
          updatedFormValues[index].treated_quantity = "";
        }
        // updatedFormValues[index].treated_quantity = TreatedQuantity;
        setIsPropotionUpdate(!isPropotionUpdate);
      }
    }
  
    dispatch(setFormValues(updatedFormValues));
    dispatch(setscope3Category5WasteTreatedForm(updatedFormValues));
  };

  const handleAddNew = () => {
    const form = document.querySelector("#purchase-Scope3Category5-form");
    if (!form.checkValidity()) {
      const firstInvalidControl = form.querySelector(":invalid");
      toast.error(
        `Error: ${firstInvalidControl.validationMessage}`
      );
      firstInvalidControl.focus();
      return
    }

    if (!validateTreatedQuantities(formValues)) {
      return;
    }
    setShouldUpdate(!shouldUpdate);
    dispatch(
      setFormValues([
        ...formValues,
        {
          ...initialFormValue,
          treated_site_id: scope3Category5WasteProduceForm.produced_site_id,
          treated_unit_id: scope3Category5WasteProduceForm.produced_unit_id,
          waste_material_id:
            scope3Category5WasteProduceForm.produced_waste_material_id,
          waste_material:
            scope3Category5WasteProduceForm.produced_waste_material,
        },
      ])
    );
  };
  const handleCopy = (index,formValue) => {
    const form = document.querySelector("#purchase-Scope3Category5-form");
    if (!form.checkValidity()) {
      const firstInvalidControl = form.querySelector(":invalid");
      toast.error(`Error: ${firstInvalidControl.validationMessage}`);
      firstInvalidControl.focus();
      return;
    }
    const newFormValues = [...formValues];
    newFormValues.splice(index + 1, 0, { ...formValue,treated_proportion_of_waste:0,treated_quantity:0  });
    if (!validateTreatedQuantities(newFormValues)) {
      return;
    }
    dispatch(setFormValues(newFormValues));
  };

  const handleRemove = (index) => {
    // if (formValues.length > 1) {
      dispatch(setFormValues(formValues.filter((_, i) => i !== index)));
    // }
  };

  const handlePurchaseFormSubmit = (e) => {
    e.preventDefault();
    if (!validateTreatedQuantities(formValues)) {
      return;
    }

    const form = document.querySelector("#purchase-Scope3Category5-form");
    if (form.length && form.checkValidity()) {
      
        const subIds = formValues.map(() => uuidv4());
        switch (actionType) {
          case 'edit':
            
            dispatch(
              updatescope3Category5Data({ api,id:scope3Category5WasteProduceForm.id, formValues:formValues.map((i,index)=>{
                return {
                  ...i,
                  sub_id:subIds[index],id:scope3Category5WasteProduceForm.id
                }
              }) })
            );
            break;
            case 'copy':
              dispatch(
                postscope3Category5WasteTreatedData({ api, formValues:formValues.map((i,index)=>{
                  return {
                    ...i,
                    sub_id:subIds[index]
                  }
                }) })
              );
            break;
        
          default:
            break;
        }

        closePurchasePopupForm();
      
    } else if (form.length) {
      form.querySelector('input[type="submit"]').click();
    }
  };
  useEffect(() => {
    const updatedFormValues = formValues.map((fv) => ({
      ...fv,
      treated_site_id: scope3Category5WasteProduceForm.produced_site_id,
      treated_unit_id: scope3Category5WasteProduceForm.produced_unit_id,
      waste_material_id:
        scope3Category5WasteProduceForm.produced_waste_material_id,
      waste_material: scope3Category5WasteProduceForm.produced_waste_material,
    }));
   
    dispatch(setFormValues(updatedFormValues));
  }, [
    scope3Category5WasteProduceForm.produced_site_id,
    scope3Category5WasteProduceForm.produced_waste_material_id,
    shouldUpdate,
  ]);
  const validateTreatedQuantities = (formValues) => {
    const treatedPeriods = formValues.map((item) => {
      return {
        treatedStart: item.treated_period_start,
        treatedEnd: item.treated_period_end,
        treatedQuantity: Number(item.treated_quantity),
      };
    });

    const result = calculateAllowableQuantity(
      produced_period_start,
      produced_period_end,
      Number(produced_quantity),
      treatedPeriods
    );

    if (typeof result === "string" && result === "All treated quantities are valid.") {
      setisExceed(false);
     
      return true
    } else {
      setisExceed(true);
      toast.error(result,{theme:"dark"});
      return false
    }
  };
  useEffect(() => {
  // validateTreatedQuantities()
   
  }, []);
  return (
    <>
      <div className="flex flex-col justify-center items-center rounded-t-md text-xs">
        <form
          style={{
            opacity: loading ? 0.5 : 1,
            pointerEvents: loading ? "none" : "auto",
          }}
          id="purchase-Scope3Category5-form"
          className="flex flex-col w-full py-4"
          onSubmit={handlePurchaseFormSubmit}
        >
          {formValues?.length>0?formValues.map((formValue, index) => (
            <FormGroup
              site_id={scope3Category5WasteProduceForm.produced_site_id}
              waste_material_id={
                scope3Category5WasteProduceForm.produced_waste_material_id
              }
              sourceTypeData={sourceTypeData}
              lcaActivityData={lcaActivityData}
              treatmentMechanismOptions={wasteTreatmentMapping}
              wasteMaterialOptions={wasteMaterial}
              key={index}
              formValue={formValue}
              handleFormChange={handleFormChange}
              handleRemove={handleRemove}
              currencyOptions={currencyOptions}
              unitOptions={unitOptions}
              siteData={siteData}
              index={index}
              isExceed={isExceed}
              setisExceed={setisExceed}
              handleCopy={handleCopy}
            />
          )):<h1 className="w-full flex items-center justify-center py-6">No waste treated for the time period</h1> }
          <div className="px-4 w-full py-3 flex gap-x-6 gap-y-4 items-center justify-center">
            <button
              type="button"
              onClick={handleAddNew}
              className="py-2 px-6 text-green-600 text-sm rounded-md"
            >
              Add new
            </button>
            <div className="w-1/6 flex flex-col">
              <button
                disabled={loading}
                type="button"
                className="py-2 px-6 text-green-600 text-sm rounded-md"
                onClick={() => closePurchasePopupForm()}
              >
                Cancel
              </button>
            </div>
            <div className="w-1/6 flex flex-col">
              <button
                type="submit"
                disabled={loading}
                className={`py-2 px-6 text-white text-sm ${
                  loading ? "bg-green-500 cursor-wait" : "bg-green-600"
                } rounded-md`}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Category5WasteTreatedFormEditAndCopyForm;
