
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setformData } from '../../../features/social/socialSlice';

const GenericForm = ({ config, handleSubmitData }) => {
  const loading = useSelector((state) => state.common.isLoading);
  const dispatch = useDispatch();
  const { formData } = useSelector((state) => state.social);

  // Initialize form state based on the config
  const initialState = {
    site: '', // For Site dropdown
    organization_level_flag:false,
    financialYear: '', // For Financial Year dropdown
    ...config.sections.reduce((state, section) => {
      section.rows.forEach((row) => {
        state[row.key] = section.fields.reduce((fieldState, field) => {
          fieldState[field.key] =field.key!=="remark"?'0':''; // Initialize each field value
          return fieldState;
        }, {});
      });
      return state;
    }, {}),
  };

  // Initialize formData in the Redux store if not already initialized
  useEffect(() => {
    dispatch(setformData(initialState));
  }, []);

  // Handle input changes for dynamic fields
  const handleInputChange = (rowKey, fieldKey, value) => {
   
    dispatch(
      setformData(
       { ...formData,
        [rowKey]: {
          ...formData[rowKey],
          [fieldKey]: value,
        },}
      )
    );
  };

  // Handle dropdown changes for site and financial year
  const handleDropdownChange = (fieldKey, value) => {
    dispatch(
      setformData(
        {...formData,
        [fieldKey]: value,}
      )
    );
  };

  // Handle radio button change for organization_level_flag
  const handleRadioChange = (value) => {
    dispatch(
      setformData(
        {...formData,
        organization_level_flag: value,}
      )
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSubmitData(formData);
   
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* Conditionally render Site dropdown */}
      <div className='flex justify-between w-full gap-3 lg:gap-6 mb-6'>
        {config?.isSiteOption && <div className="w-full">
        <label className="block mb-2 font-medium" htmlFor="organization_level_flag">
          Organization Level
        </label>
        <div className="flex gap-2 items-center">
          <div className="flex items-center">
            <input
              type="radio"
              name="organization_level_flag"
              id="organization_level_flag-true"
              checked={formData.organization_level_flag === true}
              onChange={() => handleRadioChange(true)}
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            />
            <label className="ml-2" htmlFor="organization_level_flag-true">
              Yes
            </label>
          </div>
          <div className="flex items-center">
            <input
              type="radio"
              name="organization_level_flag"
              id="organization_level_flag-false"
              checked={formData.organization_level_flag === false}
              onChange={() => handleRadioChange(false)}
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            />
            <label className="ml-2" htmlFor="organization_level_flag-false">
              No
            </label>
          </div>
        </div>
      </div>}
        {config?.isSiteOption && !formData?.organization_level_flag  && (
        <div className="w-full">
          <label className="block mb-2 font-medium">Site</label>
          <select
            required={formData.organization_level_flag===false}
            value={formData?.site || ''}
            onChange={(e) => handleDropdownChange('site', e.target.value)}
            className="border rounded p-2 w-full appearance-none"
          >
            <option value="">Select Site</option>
            {config?.siteOptions?.map(({site,id}) => (
              <option key={site} value={id}>
                {site}
              </option>
            ))}
          </select>
        </div>
      )}
      
      {/* Conditionally render Financial Year dropdown */}
      {config.financialYearOptions && config.financialYearOptions.length > 0 && (
        <div className="w-full">
          <label className="block mb-2 font-medium">Financial Year</label>
          <select
            value={formData?.financialYear || ''}
            onChange={(e) => handleDropdownChange('financialYear', e.target.value)}
            className="border rounded p-2 w-full appearance-none"
            required
          >
            <option value="">Select Financial Year</option>
            {config.financialYearOptions.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      )}
      </div>
      

      {/* Render sections and fields dynamically */}
      {config.sections.map((section) => (
        <div key={section.key} className="mb-6 w-full">
          <div className="flex justify-between">
            <h2 className="text-lg font-semibold mb-2 w-full py-3 ">{section.label}</h2>
            <h2 className="text-lg font-semibold mb-2 w-full py-3 ">Gender</h2>
            {section?.sub_label && (
              <div className="flex justify-center min-w-[70%]">
                <h2 className="text-md font-semibold mb-2 w-full justify-center text-center">
                  {section.sub_label}
                </h2>
              </div>
            )}
          </div>
          {section.rows.map((row) => (
            <div key={row.key} className="mb-4 flex gap-4 justify-between w-full">
              <h3 className="font-bold  flex items-center ">{row.label}</h3>
              <div className="flex min-w-[70%] justify-between gap-3">
                {section.fields.map((field) => (
                  <div key={field.key} className="flex-grow">
                    <label className="block mb-1 font-medium">{field.label}</label>
                    {field.type === 'select' ? (
                      <select
                        value={formData?.[row.key]?.[field.key] || ''}
                        onChange={(e) =>
                          handleInputChange(row.key, field.key, e.target.value)
                        }
                        className="bg-gray-200 text-neutral-700 text-xs py-1.5 px-1 border rounded w-full"
                      >
                        <option value="">Select</option>
                        {field.options.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <input
                      required
                        type={field?.type || 'text'}
                        placeholder={field?.placeholder || ''}
                        className="bg-gray-200 text-neutral-700 text-xs py-1.5 px-1 border rounded w-full"
                        value={formData?.[row?.key]?.[field?.key] || ''}
                        onChange={(e) =>
                          handleInputChange(row?.key, field?.key, e.target.value)
                        }
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ))}
      {/* Submit button */}
      <div className="px-4 w-full py-3 flex gap-x-6 gap-y-4 items-center justify-center">
        <button
          type="submit"
          disabled={loading}
          className={`py-2 px-6 text-white text-sm ${
            loading ? 'bg-green-500 cursor-wait' : 'bg-green-600'
          } rounded-md`}
        >
          Save and Continue
        </button>
      </div>
    </form>
  );
};

export default GenericForm;

