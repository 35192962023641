export function getLatestYearObjects(data,financialYear) {
    // Find the maximum financial year
    const latestYear = financialYear || Math.max(...data.map(item => parseInt(item.financial_year, 10)));

    const latestYearData = data.filter(item => parseInt(item.financial_year, 10) === latestYear);

if(latestYearData[0]?.site){
  return latestYearData.filter(item => item?.site === "OverAll" || item?.site)  
}
return latestYearData
}





export function filterDataByKeys(data, keyValues) {
  if (!Array.isArray(data)) {
    throw new Error("The data should be an array of objects.");
  }

  return data.filter(item =>
    Object.entries(keyValues).every(([key, value]) => item[key] === value)
  );
}
