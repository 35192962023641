import React, { useState } from 'react';

const ContractualEmployeeForm = ({ siteOptions, yearOptions, onSubmit }) => {
  const [formData, setFormData] = useState({
    site: null,
    financialYear: '',
    organization_level_flag:false,
    contracts: [
      { contractType: '', contractDetails: '', workType: '', employeeCount: '' },
    ],
  });

  const handleInputChange = (index, field, value) => {
    setFormData((prevState) => {
      const updatedContracts = [...prevState.contracts];
      updatedContracts[index][field] = value;
      return { ...prevState, contracts: updatedContracts };
    });
  };

  const handleDropdownChange = (field, value) => {
    setFormData((prevState) => ({ ...prevState, [field]: value }));
  };

  const handleAddRow = () => {
    setFormData((prevState) => ({
      ...prevState,
      contracts: [
        ...prevState.contracts,
        { contractType: '', contractDetails: '', workType: '', employeeCount: '' },
      ],
    }));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
 
  };
  const handleRadioChange = (value) => {
    setFormData((prevState) => ({
      ...prevState,
      organization_level_flag: value,
    }));
  };
  return (
    <form onSubmit={handleFormSubmit} className="container mx-auto p-4">
        <div className='flex justify-between gap-2'>
          { <div className="w-full">
        <label className="block mb-2 font-medium" htmlFor="organization_level_flag">
          Organization Level
        </label>
        <div className="flex gap-2 items-center">
          <div className="flex items-center">
            <input
              type="radio"
              name="organization_level_flag"
              id="organization_level_flag-true"
              checked={formData.organization_level_flag === true}
              onChange={() => handleRadioChange(true)}
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            />
            <label className="ml-2" htmlFor="organization_level_flag-true">
              Yes
            </label>
          </div>
          <div className="flex items-center">
            <input
              type="radio"
              name="organization_level_flag"
              id="organization_level_flag-false"
              checked={formData.organization_level_flag === false}
              onChange={() => handleRadioChange(false)}
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            />
            <label className="ml-2" htmlFor="organization_level_flag-false">
              No
            </label>
          </div>
        </div>
      </div>}    {/* Site Dropdown */}

      { !formData?.organization_level_flag  &&<div className="w-full">
        <label className="block mb-2 font-medium">Choose Site</label>
        <select
          value={formData.site}
          onChange={(e) => handleDropdownChange('site', e.target.value)}
          className="border rounded p-2 w-full"
          required
        >
          <option value="">Select Site</option>
          {siteOptions?.map(({id,site}) => (
            <option key={site} value={id}>
              {site}
            </option>
          ))}
        </select>
      </div>}
      
      {/* Financial Year Dropdown */}
      <div className="w-full">
        <label className="block mb-2 font-medium">Financial Year</label>
        <select
          value={formData.financialYear}
          onChange={(e) => handleDropdownChange('financialYear', e.target.value)}
          className="border rounded p-2 w-full"
          required
        >
          <option value="">Select Financial Year</option>
          {yearOptions?.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
        </div>
    

      {/* Contracts Table */}
      <div className="mb-4">
        <h3 className="text-lg font-semibold mb-2">Contractual Employee Details</h3>
        <table className="w-full table-auto border-collapse">
          <thead>
            <tr className="bg-gray-200">
              <th className="p-2 border border-gray-300">Contract Type</th>
              <th className="p-2 border border-gray-300">Contract Details</th>
              <th className="p-2 border border-gray-300">Type of Work</th>
              <th className="p-2 border border-gray-300">Number of Employees</th>
              <th className="p-2 border border-gray-300">Add Row</th>
            </tr>
          </thead>
          <tbody>
            {formData.contracts.map((contract, index) => (
              <tr key={index}>
                <td className="p-2 border border-gray-300">
                  <input
                    type="text"
                    placeholder="Contract Type"
                    className="border rounded p-2 w-full"
                    required
                    value={contract.contractType}
                    onChange={(e) =>
                      handleInputChange(index, 'contractType', e.target.value)
                    }
                  />
                </td>
                <td className="p-2 border border-gray-300">
                  <input
                    type="text"
                    placeholder="Contract Details"
                    required
                    className="border rounded p-2 w-full"
                    value={contract.contractDetails}
                    onChange={(e) =>
                      handleInputChange(index, 'contractDetails', e.target.value)
                    }
                  />
                </td>
                <td className="p-2 border border-gray-300">
                  <input
                    type="text"
                    placeholder="Type of Work"
                    className="border rounded p-2 w-full"
                    required
                    value={contract.workType}
                    onChange={(e) =>
                      handleInputChange(index, 'workType', e.target.value)
                    }
                  />
                </td>
                <td className="p-2 border border-gray-300">
                  <input
                    type="number"
                    placeholder="Number of Employees"
                    className="border rounded p-2 w-full"
                    required
                    value={contract.employeeCount}
                    onChange={(e) =>
                      handleInputChange(index, 'employeeCount', e.target.value)
                    }
                  />
                </td>
                <td className="p-2 border border-gray-300 text-center">
                  <button
                    type="button"
                    onClick={handleAddRow}
                    className="text-blue-500 hover:underline"
                  >
                    + Add Row
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Submit Button */}
      <div className="mt-4 flex justify-center">
        <button
          type="submit"
          className="py-2 px-4 bg-green-600 text-white rounded hover:bg-green-700"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default ContractualEmployeeForm;
